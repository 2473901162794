import {Paper} from '@material-ui/core';
import React from 'react';
import {IWidgetConfig} from '../../../interfaces';
import {TCheckBoxEventFunction} from '../../../types';
import {getPrivacyCheckNeeded, getUrl} from '../../../utils';
import DataProtectionStatement from '../../DataProtectionStatement/DataProtectionStatement';
import {useSpeedDialDataProtectionStatementStyles} from '../widgetChip.styles';

interface IProps {
    chipOpen?: boolean;
    config: IWidgetConfig;
    checkboxChecked: boolean;
    onChange: TCheckBoxEventFunction;
}

const ChipDataProtectionStatement = (props: IProps) => {
    const {config, checkboxChecked, onChange, chipOpen} = props;

    const privacyCheckNeeded = getPrivacyCheckNeeded(config);
    const classes = useSpeedDialDataProtectionStatementStyles(props);
    const formControlClassName =
        chipOpen && privacyCheckNeeded
            ? classes.formControlPaper
            : classes.formControlLabelHidden;

    return (
        <Paper className={formControlClassName}>
            <DataProtectionStatement
                config={config}
                checkboxChecked={checkboxChecked}
                onChange={onChange}
                align={'center'}
            />
        </Paper>
    );
};

export default ChipDataProtectionStatement;
