export enum EMessengerId {
    WhatsApp = 'WA',
    WhatsAppBusiness = 'WB',
    Viber = 'VI',
    InstaAndNotify = 'IN',
    Telegram = 'TG',
    Facebook = 'FB',
    WN = 'WN',
    IMessage= 'IM',
    None = 'none',
}
