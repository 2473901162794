export enum EPrivacyCheck {
    Disabled,
    Enabled,
}

export enum EWidgetTypeId {
    Unkown,
    Card,
    Chip,
    Cube,
}

export enum EWidgetSettingName {
    BackgroundColorLeft = 'backgroundColorLeft',
    BackgroundColorRight = 'backgroundColorRight',
    ButtonColor = 'buttonColor',
    ButtonTextColor = 'buttonTextColor',
    ChipColor = 'chipColor',
    ChipTextColor = 'chipTextColor',
    TextColor = 'textColor',
    ABC_ICON_ONLY = 'im_icon_only',
    CustomButtonColorEnabled = 'customButtonColorEnabled',
    CubeWidth = 'cubeWidth',
    CubeHeight = 'cubeHeight',
}

export enum EWidgetSettingType {
    Color = 'color',
    Boolean = 'boolean',
}
