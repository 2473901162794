import './polyfills';

import React from 'react';
import {render} from 'react-dom';
import {TOP_LEVEL_CONTAINER_ID} from './lib/constants/const';
import {EEnvironment, EWidgetSettingType} from './lib/enums';
import {IWidgetConfig} from './lib/interfaces';
import MessengerPeopleWidget from './lib/MessengerPeopleWidget';

/**
 * this is the old mechanism of replacing the widgets. we have to keep this because of backwards compability
 */
const mountScripts = () => {
    // get all card and chip widget script tags
    // @ts-ignore
    window.mpWidgetCardScriptTags = Array.from(document.querySelectorAll('[data-widget=\'widgetCard\']'));
    // @ts-ignore
    window.mpWidgetCubeScriptTags = Array.from(document.querySelectorAll('[data-widget=\'widgetCube\']'));
    // @ts-ignore
    window.mpWidgetChipScriptTags = Array.from(document.querySelectorAll('[data-widget=\'widgetChip\']'));

    // @ts-ignore
    const tagsToRender = [...window.mpWidgetCardScriptTags, ...window.mpWidgetChipScriptTags, ...window.mpWidgetCubeScriptTags];

    for (const scriptTag of tagsToRender) {
        const mountingPoint = document.createElement('div');

        const {id, widget} = scriptTag.dataset;
        mountingPoint.id = `${TOP_LEVEL_CONTAINER_ID}${id}`;

        if (widget === 'widgetCard' || widget === 'widgetCube') {
            // replace script tag with container div ONLY CARD WIDGET
            scriptTag.parentNode.replaceChild(mountingPoint, scriptTag);
        } else {
            // for chip widget so the position: fixed does not get caught somewhere on the website
            document.body.appendChild(mountingPoint);
            scriptTag.parentNode.removeChild(scriptTag);
        }

        const idFromUrl = new URL(window.location.href).searchParams.get('id');

        if (!widget && !idFromUrl) {
            console.error('The property data-widget is missing in the Messengerpeople widget script tag. Without this property the widget cannot be mounted.');
        }

        const idToUse = id ? id : idFromUrl;

        if (!idToUse) {
            console.error('The Messengerpeople widget cannot be mounted without the unique id.');
        }

        // tslint:disable-next-line:max-line-length
        render(<MessengerPeopleWidget id={idToUse} style={scriptTag.dataset.style}
                                      env={scriptTag.dataset.env}/>, mountingPoint);
    }

    // @ts-ignore
    delete window.mpWidgetChipScriptTags;
    // @ts-ignore
    delete window.mpWidgetCardScriptTags;
};

// when localhost, render the widget for developing purposes if not mount the scripts
if (process.env.NODE_ENV === 'production') {
    // if not localhost, then replace the script tags with instances of the widget
    mountScripts();
} else {
    const rootEl = document.getElementById('root');

    // all the test cases
    const cardId = '60015deed7c4cd6942.76338423';
    const chipId = '18225cc193cb42c243.31024619';
    const idFromUrl = new URL(window.location.href).searchParams.get('id');

    const sampleAPIResponse = {
        widgets: {
            '21545d09fe6fceb873.34346188':
                {
                    showABCIconOnly: 1,
                    uniqueid: '21545d09fe6fceb873.34346188',
                    name: 'csdfsdf',
                    widget_type_id: 2,
                    title: 'Contact us directly.',
                    body: 'Send your message to %channel% at any time via WhatsApp & Co. Send your message to %channel% at any time via WhatsApp & Co. Send your message to %channel% at any time via WhatsApp & Co. ',
                    privacy_check: 0,
                    privacy_text: 'Ja, ich willige darin ein, dass meine Daten [Telefonnummer, Name und Chatverlauf] von der <MANDANT> zu Zwecken des [Zweck den Ihr Mandant etablieren möchte (Informationsbereitstellung)] an die WhatsApp Inc in die USA übermittelt werden. Mir ist bekannt, dass in den USA ein niedrigeres Datenschutzniveau besteht als in Ländern der Europäischen Union. Die %Datenschutzbedingen% habe ich zur Kenntnis genommen und akzeptiere sie.',
                    privacy_link: 'https://google.com',
                    language: 'en',
                    // selectedMessenger: 'WA',
                    messengers: {

                        IM: {
                            button_text: { de: 'Apple Chat starten' },
                            color: '#007AFF',
                            id: 'IM',
                            link: 'https://bcrw.apple.com/uççrn:biz:03def1d3-09c2-4717-b406-c20b5f4b7538?biz-intent-id=41885dc14e088143e2.60043909&biz-group-id=mp_widget&body=%link_text%',
                            link_text: 'Start',
                            name: 'Apple Business Chat',
                        },

                        /*
                         WA: {
                            id: 'WA',
                            name: 'WhatsApp Business',
                            link: 'https:\/\/api.whatsapp.com\/send?phone=%link_number%&text=%link_text%',
                            color: '#25D366',
                            body: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod ',
                            link_text: 'Please send us your message:',
                            button_text: {en: 'Chat Starten'},
                            link_number: ['4915792353188'],
                        },

VI: {
id: 'VI',
name: 'Viber',
link: '',
color: '#722496',
button_text: { en: 'Start Viber Chat' },
},
TG: {
id: 'TG',
name: 'Telegram',
link: 'https:\/\/telegram.me\/WhatsTest2Bot',
color: '#0088CC',
info: 'Search in the Telegram app for WhatsTest2Bot, click on "Start Bot" in the chat window and send us your message.',
button_text: { en: 'Start Telegram Chat' },
},
FB: {
id: 'FB',
name: 'Facebook',
link: 'https:\/\/www.m.me\/322277281202972',
color: '#0084FF',
button_text: { en: 'Start Facebook Chat' },
},

*/
                    },
                    settings: {
                        textColor: {id: 1, name: 'textColor', type: EWidgetSettingType.Color, value: '#ffffff'},
                        backgroundColorLeft: {
                            id: 2,
                            name: 'backgroundColorLeft',
                            type: EWidgetSettingType.Color,
                            value: '#2d3032',
                        },
                        // tslint:disable-next-line:max-line-length
                        backgroundColorRight: {
                            id: 3,
                            name: 'backgroundColorRight',
                            type: EWidgetSettingType.Color,
                            value: '#4b5355',
                        },
                        buttonColor: {id: 4, name: 'buttonColor', type: EWidgetSettingType.Color, value: '#00ff00'},
                        buttonTextColor: {
                            id: 5,
                            name: 'buttonTextColor',
                            type: EWidgetSettingType.Color,
                            value: '#ffffff',
                        },
                        dividerColor: {id: 6, name: 'dividerColor', type: EWidgetSettingType.Color, value: '#ffffff'},
                        customButtonColorEnabled: {
                            id: 1,
                            name: 'customButtonColorEnabled',
                            type: EWidgetSettingType.Boolean,
                            value: 0,
                        },
                        cubeHeight: {value: 800},
                        cubeWidth: {value: 800},
                    },
                },
        },
        channel_name: '\ud83c\udf6d DEV Test 2',
        code: 200,
    };

    // @ts-ignore
    const sampleConfig: IWidgetConfig = {
        ...sampleAPIResponse.widgets['21545d09fe6fceb873.34346188'],
        channelName: sampleAPIResponse.channel_name,
    };

    // just enable the testcase you´d like
    render(
        // <NewFbMessengerIcon disabled={true} />
        <MessengerPeopleWidget
            // for script case
            // id={cardId}
            id={'105965fae5e024fdda0.45159672'}
            // directlink and iframe case
            // id={idFromUrl}
            // mcp widget editor case
            //  config={sampleConfig}
            // live env is not cached and goes to rest.
            env={EEnvironment.Live}
        />
        ,
        rootEl,
    );
}
