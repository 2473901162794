import {Typography} from '@material-ui/core';
import React from 'react';
import {CUBE_BREAKPOINT_BIG, CUBE_BREAKPOINT_SMALL} from '../../../constants';
import {EMessengerId, EWidgetSettingName} from '../../../enums';
import {EWidgetCardCustomBreakpoint} from '../../../enums/component.enums';
import {IMessenger, IWidgetConfig} from '../../../interfaces';
import {
    getCubeWidthForSelection,
    getIconClassName,
    getMobileOperatingSystem,
    getSettingFromConfigByName, isBrowserSafari,
} from '../../../utils';
import MessengerIcon from '../../Icons/MessengerIcon';
import {useWidgetCardMessengerIconStyles} from '../../WidgetCard/widgetCard.styles';
import {useWidgetCubeMessengerChipSelectionStyles, useWidgetCubeMessengerChipStyles} from '../widgetCube.styles';

const MessengerChip: React.FC<{
    config?: IWidgetConfig,
    messengerEntry: IMessenger,
    setSelectedMessenger: (messenger: EMessengerId) => void,
}> = (props) => {
    const {messengerEntry, config} = props;
    const textColor = getSettingFromConfigByName(config, EWidgetSettingName.TextColor);
    const classesForIcon = useWidgetCardMessengerIconStyles(props);
    const classes = useWidgetCubeMessengerChipStyles(props);
    const iconClass = getIconClassName(EWidgetCardCustomBreakpoint.Large, classesForIcon);
    const isBrowserSafariBool = isBrowserSafari();
    const iconContainer = isBrowserSafariBool ? classes.iconIOS : classes.icon;

    return (
        <div className={classes.chip} onClick={() => props.setSelectedMessenger(messengerEntry.id)}>
            <div className={iconContainer}>
                <MessengerIcon
                    disabled={false}
                    messenger={messengerEntry}
                    className={iconClass}
                    chipWidget={false}
                    config={config}
                />
            </div>

            <Typography style={{color: textColor}} className={classes.typo}>
                {messengerEntry.name}
            </Typography>
        </div>
    );
};
const MessengerRow: React.FC = (props) => <div style={{display: 'flex'}}>{props.children}</div>;
const MessengerColumn: React.FC =
    (props) => <div style={{display: 'flex', flexDirection: 'column'}}>{props.children}</div>;

const getMessengerColumns = (
    messenger: IMessenger[],
    rowCount: number,
    config: IWidgetConfig,
    setSelectedMessenger: (messenger: EMessengerId) => void,
) => {

    const sortedMessengers = messenger.sort(((a, b) => {
        if (!a.sequence || !b.sequence) {
            return 0;
        }
        return (a.sequence - b.sequence);
    }));

    const mapMessengersToChip = (messengerArray: IMessenger[]) => Object.values(messengerArray).map(
        (messengerEntry: IMessenger) => {
            return <MessengerChip
                key={messengerEntry.id}
                setSelectedMessenger={setSelectedMessenger}
                messengerEntry={messengerEntry}
                config={config}
            />;
        },
    );

    const width = getCubeWidthForSelection(config);

    if (width < CUBE_BREAKPOINT_SMALL) {
        return [<MessengerColumn>{mapMessengersToChip(sortedMessengers)}</MessengerColumn>];
    }

    if (width >= CUBE_BREAKPOINT_SMALL && width < CUBE_BREAKPOINT_BIG) {
        const arrayOne = sortedMessengers.splice(0, 2);
        const arrayTwo = sortedMessengers.splice(0, 2);
        const rest = sortedMessengers;

        const columnOne = <MessengerRow key={'A'}>{mapMessengersToChip(arrayOne)}</MessengerRow>;
        const columnTwo = <MessengerRow key={'B'}>{mapMessengersToChip(arrayTwo)}</MessengerRow>;
        const columnThree = <MessengerRow key={'C'}>{mapMessengersToChip(rest)}</MessengerRow>;

        if (rest.length < 1) {
            return [columnOne, columnTwo];
        }

        return [columnOne, columnTwo, columnThree];
    }

    const arrayOneWithThreeMessenger = sortedMessengers.splice(0, 3);
    const arrayTwoWithThreeMessenger = sortedMessengers.splice(0, 3);
    const restWithThree = sortedMessengers;

    const columnOne = <MessengerRow key={'A'}>{mapMessengersToChip(arrayOneWithThreeMessenger)}</MessengerRow>;
    const columnTwo = <MessengerRow key={'B'}>{mapMessengersToChip(arrayTwoWithThreeMessenger)}</MessengerRow>;
    const columnThree = <MessengerRow key={'C'}>{mapMessengersToChip(restWithThree)}</MessengerRow>;

    return [columnOne, columnTwo, columnThree];
};

const WidgetCubeMessengerSelection: React.FC<{
    config: IWidgetConfig;
    setSelectedMessenger: (messenger: EMessengerId) => void;
}> = (props) => {
    const messenger = props.config.messengers;
    const messengerValues = Object.values(messenger);
    const rowCount = Math.ceil(messengerValues.length / 2);
    const classes = useWidgetCubeMessengerChipSelectionStyles(props);

    return (
        <div className={classes.cubeMessengerSelection}>
            {getMessengerColumns(messengerValues, rowCount, props.config, props.setSelectedMessenger)}
        </div>
    );
};

export default WidgetCubeMessengerSelection;
