import {API_VERSION, DEFAULT_PROTOCOL, HTTP_PROTOCOL} from '../constants';
import {EEnvironment, EEnvironmentName, EMessengerId} from '../enums';
import {IMessenger, IWidgetConfig} from '../interfaces';
import {getMobileOperatingSystem} from './components.utils';
import {isClientIosWithViableVersion} from './miscellaneous.utils';
import {LINK_NUMBER_PLACEHOLDER, LINK_TEXT_PLACEHOLDER} from '../constants/const';

const getApiUrl = (env: EEnvironmentName): string =>
    `${DEFAULT_PROTOCOL}${env}.messengerpeople.com/api/v${API_VERSION}/widget_info?apikey=`;

export const baseUrl = (id: string, env?: EEnvironment): string => {
    if (env === EEnvironment.CDN) {
        return `https://widget.msgp.pl/data_${id}.json`;
    }

    const environmentFormatted = Number(env);

    if (environmentFormatted === EEnvironment.Staging) {
        return `${getApiUrl(EEnvironmentName.Staging)}${id}`;
    }
    if (environmentFormatted === EEnvironment.Dev) {
        return `${getApiUrl(EEnvironmentName.Dev)}${id}`;
    }
    if (environmentFormatted === EEnvironment.Live) {
        return `${getApiUrl(EEnvironmentName.Live)}${id}`;
    }

    throw Error('configuration not found, pls check baseUrl method');
};

export const getWhatsAppLink = (widgetUniqueId: string): string => {
    return `https://app.messengerpeople.com/forward?widget=${widgetUniqueId}`;
};

export const getViberLink = (widgetUniqueId: string): string => {
    return `https://app.messengerpeople.com/forward?id=VI&widget=${widgetUniqueId}`;
};

export const getViberNewApiLink = (widgetUniqueId: string): string => {
    return `https://app.messengerpeople.com/forward?id=VI_NEW&widget=${widgetUniqueId}`;
};

export const getInstaLink = (widgetUniqueId: string): string => {
    return `https://notify.messengerpeople.com/applink/wid/${encodeURI(widgetUniqueId)}/open`;
};

export const getIMessageLink = (config: IWidgetConfig, messenger: IMessenger): string => {
    const valid = isClientIosWithViableVersion();
    const {uniqueid} = config;

    if (!valid) {
        return `https://app.messengerpeople.com/forward?id=${EMessengerId.IMessage}&widget=${uniqueid}&number=${config.id}`;
    }

    return replaceLinkPlaceholder(messenger);
};

export const getUrl = (url: string): string => {
    if (url.includes(DEFAULT_PROTOCOL) || url.includes(HTTP_PROTOCOL)) {
        return url;
    }

    return `${DEFAULT_PROTOCOL}${url}`;
};

export const redirectMessenger = (messenger: IMessenger, config: IWidgetConfig): void => {
    let link = messenger.link;

    const uniqueId = config.uniqueid;

    if (!uniqueId) {
        throw Error('No uniqueId provided for redirection.');
    }

    const whatsAppStack = [EMessengerId.WhatsApp, EMessengerId.WhatsAppBusiness];

    if (whatsAppStack.includes(messenger.id as EMessengerId)) {
        link = getWhatsAppLink(uniqueId);
    }

    if (messenger.id === EMessengerId.Viber) {
        // if messenger contains link property, then the new Viber API is enabled
        if (messenger.link) {
            const isMobile = getMobileOperatingSystem();
            // if mobile, then redirect to the app, when on the desktop redirect to the new forwarding page
            if (isMobile) {
                link = messenger.link.replace('%link_text%', messenger.link_text);
            } else {
                link = getViberNewApiLink(uniqueId);
            }
        } else {
            link = getViberLink(uniqueId);
        }
    }

    if (messenger.id === EMessengerId.InstaAndNotify) {
        link = getInstaLink(uniqueId);
    }

    if (messenger.id === EMessengerId.IMessage) {
        link = getIMessageLink(config, messenger);
    }

    window.open(link, '_blank');
};

const replaceLinkPlaceholder = (messenger: IMessenger, specialTarget?: string) => {
    const {link_number, link_text, link} = messenger;
    let replaced = specialTarget ? specialTarget : link;

    if (replaced.includes(LINK_NUMBER_PLACEHOLDER)) {
        // is used only for WA and link_number[0] is always set
        replaced = replaced.replace(LINK_NUMBER_PLACEHOLDER, link_number[0]);
    }

    if (replaced.includes(LINK_TEXT_PLACEHOLDER)) {
        replaced = replaced.replace(LINK_TEXT_PLACEHOLDER, link_text);
    }

    return replaced;
};

export const openCopyRightUrl = () => window.open('https://www.messengerpeople.com/recommend/', '_blank');
