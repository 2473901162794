import {createStyles} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {ECustomColor} from '../../enums';
import {EWidgetCardCustomBreakpoint} from '../../enums/component.enums';
import {
    IMessengerForCardProps,
    IWidgetCardButtonProps,
    IWidgetTheme,
} from '../../interfaces';

const defaultPaper = {
    display: 'flex',
    width: '100%',
    overflow: 'hidden',
    // overwrite MuiPaper root color, which is used by checkbox
    color: 'inherit',
};

export const useWidgetCardStyles = makeStyles((theme: IWidgetTheme) => {
    return createStyles(
        {
            emptyWidgetPaper: {
                ...defaultPaper,
                maxWidth: 300,
                backgroundColor: theme.widgetCustomSettings.backgroundColorLeft,
            },
            paper: {
                ...defaultPaper,
                maxWidth: 500,
                backgroundColor: theme.widgetCustomSettings.backgroundColorLeft,
            },
            paperExtraSmall: {
                ...defaultPaper,
                letterSpacing: '-0.8px',
                maxWidth: 500,
                backgroundColor: theme.widgetCustomSettings.backgroundColorLeft,
            },
        },
    );
});

export const useWidgetCardButtonStyles = makeStyles((theme: IWidgetTheme) => ({
    button: {
        'borderRadius': 2,
        'padding': (props: IWidgetCardButtonProps) => {
            let padding: string | number = '16px 24px';

            switch (props.breakpoint) {
                case EWidgetCardCustomBreakpoint.Medium:
                    padding = 12;
                    break;
                case EWidgetCardCustomBreakpoint.Small:
                    padding = 8;
                    break;
                case EWidgetCardCustomBreakpoint.ExtraSmall:
                    padding = '4px 0';
                    break;
            }

            return padding;
        },
        'color': theme.widgetCustomSettings.buttonTextColor,
        'backgroundColor': theme.widgetCustomSettings.buttonColor,
        'transition': 'all 0.2s ease-in-out',
        '&:hover': {
            opacity: 0.85,
            backgroundColor: theme.widgetCustomSettings.buttonColor,
        },
    },
}));

export const useWidgetCardCopyrightStyles = makeStyles(() => ({
    copyright: {
        'fontSize': '12px !important',
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));

export const useWidgetCardContentContainerStyles = makeStyles((theme: IWidgetTheme) => createStyles({
    contentContainer: {
        flex: 1,
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: theme.spacing(2),
        paddingBottom: 8,
        backgroundColor: theme.widgetCustomSettings.backgroundColorRight,
    },
    textContainer: {
        paddingLeft: 4,
        color: theme.widgetCustomSettings.textColor,
    },
    privacyLink: {
        '&:link': {
            color: ECustomColor.Link,
            fontWeight: 500,
        },
        '&:visited': {
            color: ECustomColor.LinkVisited,
        },
    },
    bodyText: {
        fontWeight: 300,
    },
    checkbox: {
        color: theme.widgetCustomSettings.textColor,
    },
    checkboxLabel: {
        color: theme.widgetCustomSettings.textColor,
    },
    checkboxContainer: {
        marginBottom: theme.spacing(2),
    },
    checkboxHidden: {
        display: 'none',
    },
}));

export const useWidgetCardMessengerContainerStyles = makeStyles(() => createStyles({
    messengerContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
    },
}));

export const useWidgetCardMessengerIconStyles = makeStyles((theme: IWidgetTheme) => createStyles({
    iconButton: {
        '&:hover': {
            cursor: 'pointer',
        },
        'margin': (props: IMessengerForCardProps) => {
            return props.messengerContainerBreakpoint === EWidgetCardCustomBreakpoint.ExtraSmall
                ? 10
                : theme.spacing(2);
        },
    },
    iconLarge: {
        minWidth: 50,
        minHeight: 50,
        width: 50,
        height: 50,
    },
    iconMedium: {
        minWidth: 42,
        minHeight: 42,
        width: 42,
        height: 42,
    },
    iconSmall: {
        minWidth: 35,
        minHeight: 35,
        width: 35,
        height: 35,
    },
    iconExtraSmall: {
        minWidth: 25,
        minHeight: 25,
        width: 25,
        height: 25,
    },
}));
