import {Typography} from '@material-ui/core';
import React from 'react';
import {CHANNEL_TO_REPLACE} from '../../../constants';
import {EMessengerId} from '../../../enums';
import {IWidgetConfig} from '../../../interfaces';
import {useWidgetCubeSelectionPageStyles} from '../widgetCube.styles';
import WidgetCubeMessengerSelection from './WidgetCubeMessengerSelection';

const WidgetCubeSelectionPage: React.FC<{
    config: IWidgetConfig,
    setSelectedMessenger: (messenger: EMessengerId) => void;
}> = (props) => {
    const classes = useWidgetCubeSelectionPageStyles(props);
    const body = props.config && props.config.body
        ? props.config.body.replace(CHANNEL_TO_REPLACE, props.config.channelName)
        : '';

    return (
        <div className={classes.cubeSelectionContainer}>
            <Typography className={classes.title}>
                {props.config.title}
            </Typography>
            <Typography
                align={'center'}
                className={classes.body}
                variant={'caption'}
            >
                {body}
            </Typography>
            <WidgetCubeMessengerSelection config={props.config} setSelectedMessenger={props.setSelectedMessenger}/>
        </div>
    );
};

export default WidgetCubeSelectionPage;
