import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {ECustomColor, EWidgetSettingName} from '../../enums';
import {IConfig} from '../../interfaces';
import {getWidgetSettingValue} from '../../utils';

export const useWidgetChipStyles = makeStyles((theme: Theme) => {
    return createStyles(
        {
            copyright: {
                position: 'relative',
                height: theme.spacing(3),
                cursor: 'pointer',
                fontSize: 10,
            },
            copyrightText: {
                bottom: 0,
                right: '-0.8em',
                whiteSpace: 'nowrap',
                position: 'absolute',
                fontSize: 'inherit',
            },
        },
    );
});

export const useSpeedDialDataProtectionStatementStyles = makeStyles(() => createStyles({
    formControlPaper: {
        alignSelf: 'flex-end',
        alignContent: 'center',
        display: 'flex',
        height: '33%',
        borderRadius: 24,
        margin: '0 8px',
        overflow: 'hidden',
    },
    formControlLabelHidden: {
        display: 'none',
    },
    checkbox: {
        flex: 1,
        alignSelf: 'center',
    },
}));

export const useWidgetChipSpeedDialStyles = makeStyles(() => {
    return createStyles(
        {
            flex: {
                fontSize: 24,
                display: 'flex',
            },
            speedDialButton: {
                fontSize: 24,
                'pointerEvents': 'auto',
                'transition': 'all 0.2s ease-in-out',
                '&:hover': {
                    opacity: 0.82,
                    color: (props: IConfig) =>
                        getWidgetSettingValue(props.config.settings, EWidgetSettingName.ChipTextColor),
                    backgroundColor: (props: IConfig) =>
                        getWidgetSettingValue(props.config.settings, EWidgetSettingName.ChipColor),
                },
            },
            speedDialAction: {
                backgroundColor: ECustomColor.White,
            },
            icon: {
                maxHeight: 40,
                maxWidth: 40,
                minWidth: 40,
                minHeight: 40,
            },
            iconForSpecialConfig: {
                maxHeight: 60,
                maxWidth: 60,
                minWidth: 60,
                minHeight: 60,
            },
        },
    );
});
