import {StylesProvider} from '@material-ui/core';
import {ThemeProvider} from '@material-ui/styles';
import React from 'react';
import {Helmet} from 'react-helmet';
import WidgetCard from './components/WidgetCard/WidgetCard';
import WidgetChip from './components/WidgetChip/WidgetChip';
import WidgetCube from './components/WidgetCube/WidgetCube';
import {EEnvironment, EWidgetTypeId} from './enums';
import {IWidgetConfig} from './interfaces';
import {getWidgetTheme} from './miscellanious/theme';
import {
    didSettingsValuesChangeInWidgetConfig,
    generateClassName,
    getMessengerWithPriority,
    getStylesFromProps,
} from './utils';
import {widgetDetailsGet} from './utils/requests';

interface IProps {
    id?: string;
    config?: IWidgetConfig;
    env?: EEnvironment;
    style?: string;
}

interface IState {
    config?: IWidgetConfig;
    channelName?: string;
    prefix: string;
}

class MessengerPeopleWidget extends React.Component<IProps, IState> {
    public state = {
        channelName: undefined,
        config: undefined,
        prefix: '',
    };

    /**
     * load the widget data initially
     */
    public componentDidMount = () => {
        const {config} = this.props;

        if (config) {
            this.setState({config});
            return;
        }

        this.getWidgetData();
    }

    /**
     * if no config is in props, then the widget is in website of customer and does not need to rerender
     * if config is provided, rerender if the values of the settings changed, this is the editor mode in MCP
     *
     * @param prevProps
     * @param prevState
     * @param snapshot
     */
    public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (!prevState.config && this.state.config) {
            const {uniqueid, widget_type_id} = this.state.config;
            const type = String(widget_type_id);
            this.setState({prefix: `${type}-${uniqueid}-`});
        }

        if (!this.props.config) {
            return;
        }

        if (didSettingsValuesChangeInWidgetConfig(prevProps.config, this.props.config)) {
            this.setState({config: this.props.config});
        }
    }

    public getWidgetToRender = () => {
        const config = this.getConfigToRender();

        const {channelName} = this.state;
        const styles = getStylesFromProps(this.props.style);

        switch (config.widget_type_id) {
            case EWidgetTypeId.Card:
                return <WidgetCard config={config} channelName={channelName}/>;
            case EWidgetTypeId.Chip:
                return <WidgetChip config={config} styles={styles}/>;
            case EWidgetTypeId.Cube:
                return <WidgetCube config={config} styleFromProps={this.props.style}/>;
            default:
                throw Error('Widget Type Id is unknown');
        }
    }

    public render():
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
        | string
        | number
        | {}
        | React.ReactNodeArray
        | React.ReactPortal
        | boolean
        | null
        | undefined {

        const {config, prefix} = this.state;

        if (!config || !prefix) {
            return null;
        }

        const messengerCheck = getMessengerWithPriority(config);

        // don´t render widget without messenger
        if (messengerCheck.length < 1) {
            return null;
        }

        const widgetToRender = this.getWidgetToRender();
        const className = generateClassName(this.state.prefix);
        const widgetTheme = getWidgetTheme(config);

        return (
            <StylesProvider generateClassName={className}>
                <ThemeProvider theme={widgetTheme}>
                    <Helmet>
                        <meta charSet='utf-8'/>
                    </Helmet>
                    {widgetToRender}
                </ThemeProvider>
            </StylesProvider>
        );
    }

    private getConfigToRender = () => {
        if (!this.state.config && !this.props.config) {
            throw Error('No config for widget found');
        }

        if (this.props.config) {
            return this.props.config;
        }

        return this.state.config;
    }

    private getWidgetData = async () => {
        let widgetUniqueId = this.props.id;

        if (!widgetUniqueId) {
            const idSplit = window.location.search.split('?id=');
            widgetUniqueId = idSplit.length > 1 ? idSplit[1] : '';
        }

        if (!widgetUniqueId) {
            throw Error('The Widget Id is needed for the Messengerpeople Widget to fetch the config data.');
        }

        // check is needed, because env can be undefined
        const environmentToUse =
            this.props.env && typeof this.props.env === 'string'
                ? Number(this.props.env)
                : EEnvironment.CDN;

        await widgetDetailsGet(widgetUniqueId, environmentToUse).then((response) => {
            const config = response.widgets[widgetUniqueId];
            this.setState({config, channelName: response.channel_name});
        });
    }
}

export default MessengerPeopleWidget;
