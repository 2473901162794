import CloseIcon from '@material-ui/icons/Close';
import {SpeedDial, SpeedDialAction, SpeedDialIcon} from '@material-ui/lab';
import React from 'react';
import {useState} from 'react';
import {IConfig, IMessenger} from '../../../interfaces';
import {TCheckBoxEventFunction} from '../../../types';
import {getDisabled, getMessengerWithPriority, redirectMessenger} from '../../../utils';
import {ChatIcon} from '../../Icons/ChatIcon';
import MessengerIcon from '../../Icons/MessengerIcon';
import {useWidgetChipSpeedDialStyles} from '../widgetChip.styles';
import ChipDataProtectionStatement from './ChipDataProtectionStatement';

const WidgetChipSpeedDial = (props: IConfig) => {
    const classes = useWidgetChipSpeedDialStyles(props);
    const {config} = props;

    const [open, setOpen] = useState(false);
    const [checkboxChecked, setCheckboxChecked] = useState(false);

    const onChange: TCheckBoxEventFunction = (event, checked) => setCheckboxChecked(checked);

    const handleStartChatClick = (messenger: IMessenger): void => {
        redirectMessenger(messenger, config);
        setOpen(false);
    };

    const disabled = getDisabled(config, checkboxChecked);
    const messengerWithPriority = getMessengerWithPriority(config);

    if (!config.privacy_check && Object.keys(config.messengers).length === 1) {
        const onlyMessenger = Object.values(config.messengers)[0];

        return (
            <div className={classes.flex}>
                <SpeedDial
                    FabProps={{className: classes.speedDialButton}}
                    style={{right: 0}}
                    ariaLabel='MessengerPeople Widget'
                    icon={
                        <MessengerIcon
                            chipWidget
                            config={config}
                            className={classes.iconForSpecialConfig}
                            messenger={onlyMessenger}
                            disabled={false}
                        />
                    }
                    open={false}
                    onClick={() => redirectMessenger(onlyMessenger, config)}
                />
            </div>
        );
    }

    return (
        <div className={classes.flex}>
            <ChipDataProtectionStatement
                chipOpen={open}
                config={config}
                onChange={onChange}
                checkboxChecked={checkboxChecked}
            />
            {/*@ts-ignore*/}
            <SpeedDial
                FabProps={{className: classes.speedDialButton}}
                style={{right: 0}}
                ariaLabel='MessengerPeople Widget'
                icon={
                    <SpeedDialIcon
                        openIcon={<CloseIcon fontSize={'inherit'}/>}
                        icon={<ChatIcon style={{marginBottom: 4}} fontSize={'inherit'}/>}
                    />
                }
                open={open}
                onClick={() => setOpen(!open)}
            >
                {messengerWithPriority.map((messenger: IMessenger) => {
                    return (
                        <SpeedDialAction
                            className={classes.speedDialAction}
                            key={messenger.name}
                            icon={
                                <MessengerIcon
                                    chipWidget
                                    config={config}
                                    className={classes.icon}
                                    messenger={messenger}
                                    disabled={disabled}
                                />
                            }
                            tooltipTitle={messenger.name}
                            onClick={() => handleStartChatClick(messenger)}
                            FabProps={{disabled}}
                            title={messenger.name}
                        />
                    );
                })}
            </SpeedDial>
        </div>
    );
};

export default React.memo(WidgetChipSpeedDial);
