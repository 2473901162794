import Typography from '@material-ui/core/Typography';
import Copyright from '@material-ui/icons/Copyright';
import React from 'react';
import {IWidgetChipProps} from '../../interfaces';
import {isWidgetChipConfigValid, openCopyRightUrl} from '../../utils';
import WidgetChipSpeedDial from './components/WidgetChipSpeedDial';
import {useWidgetChipStyles} from './widgetChip.styles';

const WidgetChip = (props: IWidgetChipProps) => {
    const {config} = props;

    const classes = useWidgetChipStyles(props);
    const isValid = isWidgetChipConfigValid(config);

    if (!isValid) {
        return null;
    }

    return (
        <div style={{position: 'fixed', bottom: 16, right: 16, zIndex: 99999}}>
            <div style={props.styles}>
                <WidgetChipSpeedDial config={config}/>
                <div
                    className={classes.copyright}
                    onClick={openCopyRightUrl}
                >
                    <Typography
                        variant={'caption'}
                        className={classes.copyrightText}
                    >
                        <Copyright style={{fontSize: props.styles.fontSize || 10}}/> MessengerPeople
                    </Typography>
                </div>
            </div>
        </div>
    );
};

export default React.memo(WidgetChip);
